import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

function createData(name, mkt, smb, hml, rmw, cma, wml, alpha, r) {
  return { name, mkt, smb, hml, rmw, cma, wml, alpha, r };
}

const rows = [
  createData('factorinvesting.eu', '1.00 (0.00)', '0.85 (0.00)', '0.40 (0.00)', '0.41 (0.03)', '0.12 (0.55)', '0.17 (0.04)', '0.19 (0.40)', '94%'),
  createData('Indépendance et Expansion Europe Small A(C)', '1.10 (0.00)', '0.81 (0.00)', '0.55 (0.00)', '0.63 (0.02)', '-0.04 (0.90)', '0.20 (0.08)', '0.40 (0.21)', '91%'),
  createData('SPDR MSCI Europe Small Cap Value Weighted', '1.20 (0.00)', '0.72 (0.00)', '0.36 (0.00)', '0.08 (0.60)', '0.19 (0.26)', '-0.03 (0.65)', '0.19 (0.31)', '97%'),
  createData('iShares MSCI EMU Small Cap', '1.21 (0.00)', '0.54 (0.00)', '0.35 (0.00)', '0.05 (0.73)', '0.03 (0.84)', '0.11 (0.10)', '-0.25 (0.17)', '97%'),
  createData('Ken French Europe SMALL HiBM (2x5) Equal Weighted', '0.73 (0.00)', '0.86 (0.00)', '0.18 (0.16)', '-0.30 (0.12)', '-0.10 (0.63)', '-0.01 (0.93)', '0.48 (0.04)', '92%'),
  createData('Ken French Europe SMALL HiBM HiOP (2x4x4) Equal Weighted', '0.84 (0.00)', '1.05 (0.00)', '0.28 (0.12)', '-0.04 (0.88)', '0.31 (0.29)', '0.03 (0.81)', '0.59 (0.08)', '87%'),
];

export default function RegressionTable() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell align="right">Mkt-RF</TableCell>
            <TableCell align="right">SmB</TableCell>
            <TableCell align="right">HmL</TableCell>
            <TableCell align="right">RmW</TableCell>
            <TableCell align="right">CmA</TableCell>
            <TableCell align="right">WmL</TableCell>
            <TableCell align="right">α</TableCell>
            <TableCell align="right">R<sup>2</sup></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.mkt}</TableCell>
              <TableCell align="right">{row.smb}</TableCell>
              <TableCell align="right">{row.hml}</TableCell>
              <TableCell align="right">{row.rmw}</TableCell>
              <TableCell align="right">{row.cma}</TableCell>
              <TableCell align="right">{row.wml}</TableCell>
              <TableCell align="right">{row.alpha}</TableCell>
              <TableCell align="right">{row.r}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
