import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { enUS, frFR } from '@mui/x-data-grid/locales';
import Flag from 'react-world-flags'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from "react-router-dom";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { RenderValueProfitabilityRank, RenderInvestmentRank, RenderMomentumRank } from './Common';
import SellComponent from './SellComponent';
import BuyComponent from './BuyComponent';
import NordicWalkingIcon from '@mui/icons-material/NordicWalking';
import { URL_BACKEND } from './Constantes';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const RenderFlag = (props) => {
  const { value } = props;
  return <Flag code={value} />;
};

const RenderPeaPme = (props) => {
  const { value } = props;
  if (value) {
    return <CheckCircleIcon />
  }
  return '';
};

const RenderDecimal = (props) => {
  const { value } = props;
  if (typeof value == 'number') {
    return parseFloat(value).toFixed(2);
  }
  return <DoNotDisturbIcon />
};

const RenderTicker = (props) => {
  const { value } = props;
  return <Link to={"/Stock/" + value.url}>{value.name!=null ? value.name : value.url}</Link>;
};

const ValueFormatterTicker = (props) => props.name ?? props.url;

const shortNameComparator = (v1, v2) => (v1.name ?? v1.url).localeCompare(v2.name ?? v2.url);

const RenderAdmin = (props) => {
  return <NordicWalkingIcon />;
};

const getHeaders = (token) => {
  if(token){
    return {
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }
  else{
    return {};
  }
}

const getField = (metric) => {
  switch (metric) {
    case 'GROSS':
      return 'grossProfitability';
    case 'OPERATING':
      return 'operatingProfitability';
    case 'FRENCH':
      return 'frenchOperatingProfitability';
    case 'BALL':
      return 'ballOperatingProfitability';
    case 'PB':
      return 'electedPriceToBook';
    case 'PE':
      return 'priceToEarningsTTM';
    case 'PS':
      return 'priceToSalesTTM';
    case 'EBIT_TEV':
      return 'ebitTev';
    default: return '';
  }
}

const getFieldName = (metric) => {
  switch (metric) {
    case 'GROSS':
      return 'Gross profitability';
    case 'OPERATING':
      return 'Operating profitability';
    case 'FRENCH':
      return 'Ken French Operating profitability';
    case 'BALL':
      return 'Ray Ball Operating profitability';
    case 'PB':
      return 'Price-to-book';
    case 'PE':
      return 'Price-to-earnings';
    case 'PS':
      return 'Price-to-sales';
    case 'EBIT_TEV':
      return 'EBIT/TEV';
    default: return '';
  }
}

const getFieldRank = (metric) => {
  switch (metric) {
    case 'GROSS':
      return 'grossProfitabilityRank';
    case 'OPERATING':
      return 'operatingProfitabilityRank';
    case 'FRENCH':
      return 'frenchOperatingProfitabilityRank';
    case 'BALL':
      return 'ballOperatingProfitabilityRank';
    case 'PB':
      return 'valueRank';
    case 'PE':
      return 'valuePERank';
    case 'PS':
      return 'valuePSRank';
    case 'EBIT_TEV':
      return 'valueEbitTevRank';
    default: return '';
  }
}

const marks = [
  {
    value: 1.30102999566,
  },
  {
    value: 1.69897000434,
  },
  {
    value: 2,
  },
  {
    value: 2.30102999566,
  },
  {
    value: 2.69897000434,
  },
  {
    value: 3,
  },
  {
    value: 3.30102999566,
  },
  {
    value: 3.69897000434,
  },
  {
    value: 4,
  },
];

const valueTextMarketCapValue = (x) => {
  if(x<1000){
    return `${Math.round(x)} M€`;
  }
  return `${Math.round(x/1000)} B€`;
}

function TableToBuy(props) {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [profitabilityMetric, setProfitabilityMetric] = useState('GROSS');
  const [valueMetric, setValueMetric] = useState('PB');
  const {type, token, rerender, handler} = props;
  const [marketCapValue, setMarketCapValue] = useState([1.30102999566,4]);
  const [commitedMarketCapValue, setCommitedMarketCapValue] = useState(marketCapValue);

  const [valueFilter, setValueFilter] = useState(true);
  const [profitabilityFilter, setProfitabilityFilter] = useState(true);
  const [momentumFilter, setMomentumFilter] = useState(true);
  const [investmentFilter, setInvestmentFilter] = useState(true);

  const [valueThreshold, setValueThreshold] = useState(type==='buy' ? 70 : 60);
  const [profitabilityThreshold, setProfitabilityThreshold] = useState(type==='buy' ? 70 : 60);
  const [investmentThreshold, setInvestmentThreshold] = useState(20);
  const [momentumThreshold, setMomentumThreshold] = useState(50);

  const [commitedValueThreshold, setCommitedValueThreshold] = useState(valueThreshold);
  const [commitedProfitabilityThreshold, setCommitedProfitabilityThreshold] = useState(profitabilityThreshold);
  const [commitedInvestmentThreshold, setCommitedInvestmentThreshold] = useState(investmentThreshold);
  const [commitedMomentumThreshold, setCommitedMomentumThreshold] = useState(momentumThreshold);

  const handleChangeMarketCapValue = (event) => {
    setMarketCapValue(event.target.value)
  }

  const handleChangeCommitedMarketCapValue = (event) => {
    setCommitedMarketCapValue(marketCapValue)
  }

  const handleChangeValueThreshold = (event) => {
    setValueThreshold(event.target.value)
  }

  const handleChangeCommitedValueThreshold = (event) => {
    setCommitedValueThreshold(valueThreshold)
  }

  const handleChangeProfitabilityThreshold = (event) => {
    setProfitabilityThreshold(event.target.value)
  }

  const handleChangeCommitedProfitabilityThreshold = (event) => {
    setCommitedProfitabilityThreshold(profitabilityThreshold)
  }

  const handleChangeInvestmentThreshold = (event) => {
    setInvestmentThreshold(event.target.value)
  }

  const handleChangeCommitedInvestmentThreshold = (event) => {
    setCommitedInvestmentThreshold(investmentThreshold)
  }

  const handleChangeMomentumThreshold = (event) => {
    setMomentumThreshold(event.target.value)
  }

  const handleChangeCommitedMomentumThreshold = (event) => {
    setCommitedMomentumThreshold(momentumThreshold)
  }

  const handleChangeValueFilter = (event) => {
    setValueFilter(!valueFilter);
  }

  const handleChangeProfitabilityFilter = (event) => {
    setProfitabilityFilter(!profitabilityFilter);
  }

  const handleChangeInvestmentFilter = (event) => {
    setInvestmentFilter(!investmentFilter);
  }

  const handleChangeMomentumFilter = (event) => {
    setMomentumFilter(!momentumFilter);
  }

  const switchProfitabilityHandler = (event) => {
    setProfitabilityMetric(event.target.value);
  };

  const switchValueHandler = (event) => {
    setValueMetric(event.target.value);
  };

  const RenderBuy = (propsLocal) => {
    const { value } = propsLocal;
    return <BuyComponent ticker={value} token={token} handler={handler} />
  };

  const RenderSell = (propsLocal) => {
    const { value } = propsLocal;
    return <SellComponent ticker={value} token={token} handler={handler} />
  };

  const getFieldAction = () => {
    if(type==='admin' || !token){
      return { field: 'id', headerName: 'Action', width: 50, renderCell: RenderAdmin };
    }
    else if (type==='buy'){
      return { field: 'id', headerName: 'Buy', width: 50, renderCell: RenderBuy };
    }
    else{
      return { field: 'id', headerName: 'Sell', width: 50, renderCell: RenderSell };
    }
  }

  useEffect(() => {
    const baseUrl = `${URL_BACKEND}/screener/${type}?valueType=${valueMetric}&profitabilityType=${profitabilityMetric}&valueFilter=${valueFilter}&profitabilityFilter=${profitabilityFilter}&investmentFilter=${investmentFilter}&momentumFilter=${momentumFilter}&valueThreshold=${commitedValueThreshold}&profitabilityThreshold=${commitedProfitabilityThreshold}&investmentThreshold=${commitedInvestmentThreshold}&momentumThreshold=${commitedMomentumThreshold}`
    const url = props.marketCapDisabled ? baseUrl : `${baseUrl}&customMinCap=${Math.round(10**commitedMarketCapValue[0]*1000000)}&customMaxCap=${Math.round(10**commitedMarketCapValue[1]*1000000)}`;
    fetch(url, getHeaders(token))
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [valueMetric, profitabilityMetric, type, token, rerender, commitedMarketCapValue, props.marketCapDisabled, valueFilter, profitabilityFilter, investmentFilter, momentumFilter, commitedValueThreshold, commitedProfitabilityThreshold, commitedInvestmentThreshold, commitedMomentumThreshold])

  if (error) {
    if(token){
      window.location.replace(`${URL_BACKEND}/oauth2/authorization/google`);
    }
    return <div>Error : {error.message}</div>;
  } else if (!isLoaded || !items) {
    return <div>Loading...</div>;
  } else {
    const fieldAction = getFieldAction();
    return (
      <div style={{ height: '550px' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl>
              <InputLabel variant="standard" htmlFor="pm">{t('profitability-metric')}</InputLabel>
              <NativeSelect
                defaultValue={'GROSS'}
                inputProps={{
                  name: 'profitability-metric',
                  id: 'pm',
                }}
                onChange={switchProfitabilityHandler}
              >
                <option value={'GROSS'}>Gross Profitability</option>
                <option value={'OPERATING'}>Operating Profitability</option>
                <option value={'FRENCH'}>Ken French Operating Profitability</option>
                <option value={'BALL'}>Ray Ball Operating Profitability</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <InputLabel variant="standard" htmlFor="vm">{t('value-metric')}</InputLabel>
              <NativeSelect
                defaultValue={'PB'}
                inputProps={{
                  name: 'value-metric',
                  id: 'vm',
                }}
                onChange={switchValueHandler}
              >
                <option value={'PB'}>Price-to-book</option>
                <option value={'PE'}>Price-to-earnings</option>
                <option value={'PS'}>Price-to-sales</option>
                <option value={'EBIT_TEV'}>EBIT/TEV</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
          <Typography id="track-false-slider" gutterBottom>{t('market-cap-range')}</Typography>
            <Slider
              getAriaLabel={() => t('market-cap-range')}
              value={marketCapValue}
              onChange={handleChangeMarketCapValue}
              onChangeCommitted={handleChangeCommitedMarketCapValue}
              valueLabelDisplay="auto"
              getAriaValueText={valueTextMarketCapValue}
              min={1.30103}
              max={4}
              scale={(x) => 10**x}
              valueLabelFormat={valueTextMarketCapValue}
              marks={marks}
              step={null}
              disabled={props.marketCapDisabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography align="center">{t('apply-filters')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="value" disabled={props.advancedFiltersDisabled} onChange={handleChangeValueFilter} />
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="profitability" disabled={props.advancedFiltersDisabled} onChange={handleChangeProfitabilityFilter} />
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="investment" disabled={props.advancedFiltersDisabled} onChange={handleChangeInvestmentFilter} />
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
           <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="momentum" disabled={props.advancedFiltersDisabled} onChange={handleChangeMomentumFilter} />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography align="center">{t('max-500')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Slider
              getAriaLabel={() => t('value-threshold')}
              value={valueThreshold}
              onChange={handleChangeValueThreshold}
              onChangeCommitted={handleChangeCommitedValueThreshold}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disabled={type==='admin' || !valueFilter}
            />
          </Grid>
          <Grid item xs={2}>
            <Slider
              getAriaLabel={() => t('profitability-threshold')}
              value={profitabilityThreshold}
              onChange={handleChangeProfitabilityThreshold}
              onChangeCommitted={handleChangeCommitedProfitabilityThreshold}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disabled={type==='admin' || !profitabilityFilter}
            />
          </Grid>
          <Grid item xs={2}>
            <Slider
              getAriaLabel={() => t('investment-threshold')}
              value={investmentThreshold}
              onChange={handleChangeInvestmentThreshold}
              onChangeCommitted={handleChangeCommitedInvestmentThreshold}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disabled={type==='admin' || !investmentFilter}
            />
          </Grid>
          <Grid item xs={2}>
            <Slider
              getAriaLabel={() => t('momentum-threshold')}
              value={momentumThreshold}
              onChange={handleChangeMomentumThreshold}
              onChangeCommitted={handleChangeCommitedMomentumThreshold}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disabled={type==='admin' || !momentumFilter}
            />
          </Grid>
        </Grid>
        <DataGrid localeText={i18n.resolvedLanguage === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
          autoPageSize
          disableSelectionOnClick
          rows={items}
          columns={[
            { field: 'flag', headerName: t('country'), width: 20, renderCell: RenderFlag },
            { field: 'shortName', headerName: t('company'), width: 130, renderCell: RenderTicker, sortComparator: shortNameComparator, valueFormatter: ValueFormatterTicker},
            { field: 'peapme', headerName: 'PME', width: 50, renderCell: RenderPeaPme },
            { field: getField(valueMetric), headerName: getFieldName(valueMetric), align: 'right', width: 100, renderCell: RenderDecimal },
            { field: getFieldRank(valueMetric), headerName: 'Value score', align: 'right', width: 100, renderCell: RenderValueProfitabilityRank },
            { field: getField(profitabilityMetric), headerName: getFieldName(profitabilityMetric), align: 'right', width: 100, renderCell: RenderDecimal },
            { field: getFieldRank(profitabilityMetric), headerName: 'Profitability score', align: 'right', width: 100, renderCell: RenderValueProfitabilityRank },
            { field: 'investment', headerName: 'Asset growth', width: 100, renderCell: RenderDecimal, align: 'right' },
            { field: 'investmentRank', headerName: 'Investment score', align: 'right', width: 100, renderCell: RenderInvestmentRank },
            { field: 'momentumRank', headerName: 'Momentum score', align: 'right', width: 100, renderCell: RenderMomentumRank },
            fieldAction]}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            density: "compact",
            columns: {
              columnVisibilityModel: {
                id: (type!=='admin' && !!token),
                peapme: i18n.resolvedLanguage === "fr",
              },
            },
          }}
        />
      </div>
    );
  }
}

export default TableToBuy;
